import { LitElement, html, css, nothing } from "lit";
import { repeat } from "lit/directives/repeat.js";
import { EVENTS, triggerCustomEvent } from "../../utils/customEvents";
import { updateStrings } from "../../utils/strings.js";
import { getAddToCartSearchTracking } from "../search-container.utils.js";
import { pricesStyles } from "./prices";
import { variantsStyles } from "./variants";
import { stockStyles } from "./stockstatus";
import { labelsStyles } from "./labels";
import { ratingStyles } from "./ratings";
import { productNumberStyles } from "./productNumber";

import "../buyButton/buy-button.js";
import "./product-box.js";

class SearchResult extends LitElement {
  static properties = {
    products: { type: Array },
    addedproducts: { type: Array },
    strings: { type: Object },
    showcomparison: { type: Boolean },
    trackingid: { type: String },
    sort: { type: String },
    layout: { type: String },
    isflexonly: { type: Boolean },
  };

  constructor() {
    super();
    this.products = [];
    this.addedproducts = [];
    this.showcomparison = false;
    this.isComparisonReady = false;
    this.showPrices = this._getHidePricesStatus();
    this.isflexonly = false;
  }

  connectedCallback() {
    super.connectedCallback();
    this.trackSearch = getAddToCartSearchTracking(this.trackingid, this.sort);
    updateStrings(this.strings);
  }

  _getHidePricesStatus() {
    const state = localStorage.getItem("hidePrices")?.toString() ?? "";

    const hidePrices = state === 'true';

    return !hidePrices;
  }

  _clickHandler(e) {
    e.preventDefault();

    const path = e.composedPath();

    const mainLink = path.find(node =>
        node instanceof Element && node.classList?.contains('main-link')
    );
    const energyLogo = path.find(node =>
        node instanceof Element && node.classList?.contains('energy-logo')
    );

    if (e.target !== e.currentTarget) {
      if (mainLink || energyLogo) {

        const linkEl = mainLink || energyLogo;

        if (!this.trackingid) {
          window.location.href = linkEl.href;
        }

        triggerCustomEvent(EVENTS.SEARCH_CLICKED, {
          queryId: this.trackingid,
          materialNumber: linkEl.getAttribute("data-sku"),
          position: linkEl.getAttribute("data-index"),
          price: linkEl.getAttribute("data-price"),
          discount: linkEl.getAttribute("data-discount"),
          sort: this.sort,
        });

        let baseUrl = linkEl.href;

        if (baseUrl.includes("?")) {
          const [path, searchParams] = baseUrl.split("?");
          baseUrl = path;
        }

        const energyLogoParam =
            linkEl.className === "energy-logo" ? "&datablad=true" : "";
        const url = `${baseUrl}?queryid=${this.trackingid}&sort=${this.sort}${energyLogoParam}`;

        if (e.ctrlKey || e.button == 1 || e.metaKey) {
          window.open(url, "_blank").focus();
        } else if (e.button == 2) {
          linkEl.href = url;
          return;
        } else {
          window.location.href = url;
        }
      }
    }
  }

  render() {
    if(this.products.length <= 0 && this.addedproducts <= 0) {
      return  html`<div class="no-items-alert">
        <h2>${this.strings.filterNoResultsTitle}</h2>
        <p>${this.strings.filterNoResultsText}</p>
      </div>`;
    }

    return html`
      <div
        class="product-container"
        @click="${this.trackingid ? this._clickHandler : nothing}"
        @auxclick="${this.trackingid ? this._clickHandler : nothing}"
      >
        ${repeat(
          [...this.products, ...this.addedproducts],
          (product) => product.materialNumber,
          (product, index) =>
            html`
               <product-box 
                id="product-box-${index}"
                layout="${this.layout}"
                .product="${product}"
                .showcomparison="${this.showcomparison}"
                .index="${index}"
                .strings="${this.strings}"
                .isflexonly="${this.isflexonly}"
               >
               </product-box>
            `
        )}
      </div>
    `;
  }

  static styles = [
    pricesStyles,
    variantsStyles,
    stockStyles,
    labelsStyles,
    ratingStyles,
    productNumberStyles,
    css`
      * {
        box-sizing: border-box;
      }

      .no-items-alert {
        margin: 10px;

        & h2 {
          font-family: 'Barlow Semi Condensed';
          font-size: 32px;
          margin: 16px 0;
        }

        & p {
          font-size: 16px;
        }
      }
      
      :host {
        display: block;
        container: productlist / inline-size;
      }

      .product-container {
        --grid-layout-gap: 16px;
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        margin: 16px;
        gap: var(--grid-layout-gap);
      }

      :host(.list) .product-container {
        grid-template-columns: 1fr;
      }
      
      @container productlist (max-width: 1440px){
        .product-container {
          grid-template-columns: repeat(4, 1fr);
        }
      }
      @container productlist (max-width: 1208px){
        .product-container {
          grid-template-columns: repeat(3, 1fr);
        }
      }
      @container productlist (max-width: 902px){
        .product-container {
          grid-template-columns: repeat(2, 1fr);
        }
      }
      
      .product-box {
        max-width: 492px;
        //flex: 1 0 328px;
        background-color: #fff;
        box-shadow: 1px 1px 4px -2px rgba(0, 0, 0, 0.25),
          -1px -1px 4px -2px rgba(0, 0, 0, 0.25);
        text-align: center;
        padding: 12px;
        border-radius: 8px;
        text-align: start;

        display: grid;
        align-items: center;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: auto 20px 45px 34px 26px auto 34px auto;
        grid-template-areas:
          "img img"
          "variants variants"
          "name name"
          "description description"
          "rating rating"
          "price price"
          "stockstatus stockstatus"
          "buyButton buyButton";
        gap: 10px;
      }

      :host(.list) .product-container .product-box {
        max-width: 100%;
        flex-basis: auto;
        text-align: left;
        display: grid;
        grid-template-columns: minmax(100px, 278px) minmax(240px, 3fr) 1fr 1fr;
        grid-template-rows: repeat(7, auto);
        grid-template-areas:
          "img variants price ."
          "img name price ."
          "img description price ."
          "img rating price ."
          "img rating buyButton ."
          "img stockstatus buyButton compare"
          "img productnumbers . .";
        gap: 10px;
      }

      .productnumbers {
          display: none;
      }
        
      :host(.list) .productnumbers {
          display: block;
      }

      @media (max-width: 768px) {
        .product-container {
          grid-template-columns: 1fr;
        }
        
        .product-box,
        :host(.list) .product-container .product-box {
          max-width: 100%;
          flex: 1 0 100%;
          grid-template-columns: 1fr 1fr;
          grid-template-rows: repeat(6, auto);
          grid-template-areas:
            "img name"
            "img description"
            "img price"
            "rating variants"
            "stockstatus stockstatus"
            "buyButton buyButton";
          gap: 7px;

          .name {
            font-size: 14px;
          }
        }
        
        .productnumbers {
            display: none;
        }
      }

      .product-box .main-link {
        grid-column: 1 / -1;
        grid-row: 1 / -1;
        height: 100%;
        outline-offset: 8px;
      }

      .product-box:has(.main-link:not(:active)) {
        transition: background-color 1000ms;
      }

      .product-box:has(.main-link:active) {
        background: color-mix(in srgb, var(--store-color, #f8b912) 50%, white);
      }
      
      .product-box-active {
        background: color-mix(in srgb, var(--store-color, #f8b912) 50%, white);
      }

      .product-box * {
        margin: 0;
        cursor: pointer;
        pointer-events: none;
      }

      .product-box a,
      .product-box button {
        pointer-events: initial;
        cursor: pointer;
      }

      .name {
        grid-area: name;
        align-self: end;
        font-size: 18px;
        font-weight: 500;
        color: #222;
      }

      .product-box:hover .name {
        text-decoration: underline;
        text-underline-offset: 4px;

      }

      .description {
        grid-area: description;
        align-self: start;
        font-size: 14px;
      }

      .clamp-lines {
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        display: -webkit-box;
        text-wrap: balance;
      }

      .product-image {
        grid-area: img;
        width: 100%;
        height: auto;
        aspect-ratio: 200 / 150;
        background-size: contain;
        pointer-events: auto;
      }
      
      .product-image img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
      
      .product-box .product-image-second {
        display: none;
      }

      .product-image-first {
        cursor: pointer;
      }

      .product-image-first:hover {
        opacity: 0;
      }

      .product-image-first:hover + .product-image-second {
        display: block;
      }

      .comparison {
        grid-area: compare;
        display: flex;
        gap: 8px;
        justify-content: center;
        align-items: center;
        color: #565656;
        font-size: 14px;
        border: none;
        border-radius: 4px;
        background-color: #fff;
        padding: 8px 16px;
      }

      .comparison:hover {
        background-color: #f5f5f5;
        transform: translate(-1px, 0);
      }

      .cta-container {
        grid-area: buyButton;
        .buy-button {
          border: none;
          border-radius: 5px;
          padding: 8px 24px;
          color: #222;
          background-color: var(--store-color, #f8b912);
          text-align: center;
          width: 100%;
          font-size: 16px;
        }

        .buy-button:hover {
          opacity: 0.7;
        }
      }
    `,
  ];
}
customElements.define("search-result", SearchResult);