import { html, css } from "lit";
import { hasFlex } from "../../utils";
import { calcRightTextColor } from "../../utils";

const renderFlexStrings = (text, prices) => {
  return text
    .replace("{price}", prices.monthlyPrice !== undefined ? prices.monthlyPrice : '')
    .replace("{months}", prices.monthlyPriceDuration !== undefined ? prices.monthlyPriceDuration : '')
    .replace("{totalprice}", prices.monthlyPriceTotal !== undefined ? prices.monthlyPriceTotal : '');
};

const renderFlexPriceBeforeStrings = (text, prices) => {
    return text
        .replace("{price}", prices.monthlyBeforePrice !== undefined ? prices.monthlyBeforePrice : '')
        .replace("{months}", prices.monthlyPriceDuration !== undefined ? prices.monthlyPriceDuration : '')
        .replace("{totalprice}", prices.monthlyPriceTotal !== undefined ? prices.monthlyPriceTotal : '');
};

const renderFlexTotalStrings = (text, prices) => {
    return text
        .replace("{total}", prices.monthlyPriceTotalOver24Months !== undefined ? prices.monthlyPriceTotalOver24Months : '');
}

const renderCrmOnlySection = (crmOnly) => {

    if(crmOnly === null || crmOnly === undefined) {
        return "";
    }

    return html`
        <p>Margin: ${crmOnly?.margin}</p>
        ${crmOnly?.isEol === true
                ? html`<p class="isEol">End of life</p>`
                : ""}
        ${crmOnly?.isCrmOnly === true
                ? html`<p class="isCrmOnly">CRM-Only</p>`
                : ""}
    `
}

const renderPricesRegular = ({ price, storeId, tag, crmOnly }, strings, showPrice) => {
    return html`<div class="price">
    <div class="total">${showPrice ? price?.listPrice : "" } </div>
   ${price.priceIsExcludingVat ? html`<div>
       <p>${strings.exVat}</p>
   </div>` : ""}
    <div class="crmOnly">${renderCrmOnlySection(crmOnly)}</div>
    <div class="save">
      ${price?.priceTag && showPrice
        ? html`<span style=${price.priceTag?.color ? `--discount-color: ${price.priceTag?.color}; --text-color: ${calcRightTextColor(price.priceTag?.color)}` : ""}>
            ${price.priceTag.tagLabel}
          </span>`
        : ""}
    </div>
    <div class="prior">
      ${price?.discount && showPrice
        ? html`${strings.before} <span>${price?.listPriceBeforeDiscount}</span>`
        : ""}
    </div>
    <div class="flex">
      ${hasFlex(storeId) && price?.monthlyPrice && price?.isApplicableForLeasing && showPrice
        ? price?.hideFlexPrice
            ? html`${strings.flexCreditLaw} <i>FLEX&nbsp;</i>`
            : html`<i>FLEX&nbsp;</i> ${renderFlexStrings(strings.flex, price)}`
        : ""}
    </div>
  </div>`;
}

const renderPricesFlex = ({ price, storeId, tag, crmOnly }, strings, showPrice) => {
    return html`<div class="price flex">
    <div class="total">${html`<div class="flex-total">
        <span>${price.monthlyPrice !== undefined ? price.monthlyPrice : ''} </span>
        <span class="flex-price-suffix">${strings.flexPerMonth}</span>
    </div>`} </div>
    <div class="crmOnly">${renderCrmOnlySection(crmOnly)}</div>
    <div class="save">
      ${price?.priceTag && showPrice
        ? html`<span style=${price.priceTag?.color ? `--discount-color: ${price.priceTag?.color}; --text-color: ${calcRightTextColor(price.priceTag?.color)}` : ""}>
            ${strings.save + " " + (price.monthlyPriceDiscount ?? 0) + " " +strings.flexPerMonth}
          </span>`
        : ""}
    </div>
    <div class="prior">
      ${price?.discount && showPrice
        ? html`
          <div class="flex-before">
              <span>${strings.before}</span>
              <span>${renderFlexPriceBeforeStrings(strings.flex, price)}</span>
          </div>` : ""
        }
    </div>
        <div class="flex">
            ${html`${renderFlexTotalStrings(strings.flexTotal, price)}`}
        </div>
  </div>`;
}

export const pricesTemplate = ({ price, storeId, tag, crmOnly }, strings, showPrice, isflexonly) => {
    return isflexonly ?
        renderPricesFlex({ price, storeId, tag, crmOnly }, strings, showPrice) :
        renderPricesRegular({ price, storeId, tag, crmOnly }, strings, showPrice);
}

export const pricesStyles = css`
    @container product-box-container (max-width: 600px) {
        .flex-before {
            flex-direction: row;
        }
    }
    
  @media (min-width: 768px) {
    :host([layout="list"]) .price {
      grid-template-columns: 1fr;
      grid-template-rows: repeat(4, auto);
      grid-template-areas:
        "save"
        "total"
        "prior"
        "flex";

      .total {
        padding: 0;
      } 
      
      .flex-before {
          flex-direction: row;
      }
    }
  }
    
    .flex-price-suffix {
        font-size: 18px;
        padding: 5px 0px;
    }
    
    .flex-total {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        gap: 5px;
        align-items: end;
    }
    
    .exVat {
        font-size: 12px;
        font-weight: 400;
    }

  .price {
    position: relative;
    grid-area: price;
    font-family: var(--price-font, "Barlow Semi Condensed");

    display: grid;
    grid-template-columns: auto 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    grid-template-areas:
      "total save"
      "total prior"
      "flex flex";
    gap: 5px 13px;
    align-items: end;
    justify-content: left;
    .total {
      grid-area: total;
      align-self: center;
      font-size: 40px;
      font-weight: 700;
      margin-top: -5px;
    }

    .save {
      --discount-color: #f8b912;
      --text-color: #000;
      
      grid-area: save;
      font-size: 14px;
      font-weight: 600;
      text-wrap: nowrap;

      & span {
        background-color: var(--discount-color, #f8b912);
        color: var(--text-color, #000);
        border-radius: 2px;
        padding: 1px 4px 2px;
      }
    }

  .flex-before {
      display: flex; 
      flex-direction: row;
  }
  
    .prior {
      grid-area: prior;
      font-size: 14px;
      text-wrap: nowrap;
      align-self: start;

      & span {
        font-weight: 600;
      }
    }

    .flex {
      grid-area: flex;
      font-size: 14px;
      color: #1f1949;
    }
  }
    
  .price.flex {
      min-height: 135px;
  }
    
  .crmOnly {
    font-family: var(--price-font, "Barlow Semi Condensed");
    font-size: 14px;

    .isEol,
    .isCrmOnly {
      color: white;
      padding: 0 4px;
      border-radius: 4px;
      width: fit-content;
      margin-top: 4px;
    }

    .isEol {
      background-color: #c47814;
    }

    .isCrmOnly {
      background-color: #a81c13;
    }
  }

  @media (max-width: 768px) {
    .price {
      column-gap: 8px;

      .total {
        font-size: 24px;
      }

      .save,
      .prior,
      .flex {
        font-size: 12px;
      }
    }
  }
`;