import { LitElement, html, css, nothing } from "lit";
import { compareSvg } from "../../utils/svgProvider";
import { canDisplayButton, isB2C, getStoreColor, supportsReviews} from "../../utils";
import { updateStrings } from "../../utils/strings.js";
import { getAddToCartSearchTracking } from "../search-container.utils.js";
import { pricesTemplate, pricesStyles } from "./prices";
import { variantsTemplate, variantsStyles } from "./variants";
import { stockTemplate, stockStyles } from "./stockstatus";
import { labelsTemplate, labelsStyles } from "./labels";
import { ratingTemplate, ratingStyles } from "./ratings";
import { productNumberTemplate, productNumberStyles } from "./productNumber";

import "../buyButton/buy-button.js";

class ProductBox extends LitElement {
    static properties = {
        index: { type: Number },
        product: { type: Object },
        addedproducts: { type: Array },
        strings: { type: Object },
        showcomparison: { type: Boolean },
        trackingid: { type: String },
        sort: { type: String },
        layout: { type: String },
        isflexonly: { type: Boolean },
    };

    constructor() {
        super();
        this.addedproducts = [];
        this.showcomparison = false;
        this.isComparisonReady = false;
        this.showPrices = this._getHidePricesStatus();
        this.isflexonly = false;
    }

    connectedCallback() {
        super.connectedCallback();
        this.trackSearch = getAddToCartSearchTracking(this.trackingid, this.sort);
        updateStrings(this.strings);
    }

    _getHidePricesStatus() {
        const state = localStorage.getItem("hidePrices")?.toString() ?? "";

        const hidePrices = state === 'true';

        return !hidePrices;
    }


    _handleImgClick(e, index) {
        e.preventDefault();
        const aElem = this.shadowRoot.getElementById(`main-link-${index}`);
        const productBoxElem = this.shadowRoot.getElementById(`product-box-${index}`)
        productBoxElem.classList.add('product-box-active')
        aElem.click();

        setTimeout(() => {
            productBoxElem.classList.remove('product-box-active');
        }, 400);
    }

    render() {
        return html`
            <div
                id="product-box-${this.index}"
                class="product-box"
                style="--store-color: ${getStoreColor(this.product.storeId)}"
            >
                <a
                    id="main-link-${this.index}"
                    class="main-link"
                    href=${this.product.url}
                    title="${this.product.name}\n${this.product.description}"
                    aria-label=${this.product.name}
                    data-sku=${this.product.materialNumber}
                    data-index=${this.index + 1}
                    data-price=${this.product.price.listPriceExVat}
                    data-discount=${this.product.price.discountExVat}
                ></a>
                <img alt="64x64" height="150" width="200" class="product-image product-image-first"
                     src="/img/p/300/${this.product.materialNumber}.jpg"
                     loading="eager" @click=${(e) => this._handleImgClick(e, this.index)} />
                ${this.product.images?.length > 1
                ? html`
                                <img alt="64x64" height="150" width="200" class="product-image product-image-second" src="/img/p/300/${this.product.materialNumber}_1.jpg" loading="eager"/>`
                : html`
                                <img alt="64x64" height="150" width="200" class="product-image product-image-second" src="/img/p/300/${this.product.materialNumber}.jpg" loading="eager"/>`}
                ${labelsTemplate(this.product, this.strings)}
                ${variantsTemplate(this.product.variantGroups, this.strings)}
                <h2 class="name clamp-lines">${this.product.name}</h2>
                ${supportsReviews(this.product.storeId) ? ratingTemplate(this.product.reviewRating, this.strings) : nothing }
                ${pricesTemplate(this.product, this.strings, this.showPrices, this.isflexonly)}
                <p class="description clamp-lines">${this.product.description}</p>
                ${stockTemplate(this.product.stock)}
                ${isB2C(this.product.storeId) ? nothing : productNumberTemplate(this.product.materialNumber, this.product.manufacturerPartNumber, this.strings)}
                ${this.showcomparison && this.isComparisonReady
                ? html`<button class="comparison">
                          ${compareSvg} Compare.
                        </button>`
                : nothing}
                <div class="cta-container">
                    ${canDisplayButton(this.product.button) ? html`<buy-button .index=${this.index} .product=${this.product} .buycallback=${this.trackSearch} />` : nothing}
                </div>
            </div>
        `;
    }

    static styles = [
        pricesStyles,
        variantsStyles,
        stockStyles,
        labelsStyles,
        ratingStyles,
        productNumberStyles,
        css`
            * {
                box-sizing: border-box;
            }

            .no-items-alert {
                margin: 10px;

                & h2 {
                    font-family: 'Barlow Semi Condensed';
                    font-size: 32px;
                    margin: 16px 0;
                }

                & p {
                    font-size: 16px;
                }
            }

            :host {
                display: block;
                container: productlist / inline-size;
            }

            .product-container {
                --grid-layout-gap: 16px;
                display: grid;
                grid-template-columns: repeat(5, 1fr);
                margin: 16px;
                gap: var(--grid-layout-gap);
            }

            :host([layout="list"]) .product-box .main-link {
                grid-column: 1 / -1;
                grid-row: 1 / -1;
                height: 100%;
            }
            
            :host([layout="list"]) .product-container {
                grid-template-columns: 1fr;
            }

            @container productlist (max-width: 1440px) {
                .product-container {
                    grid-template-columns: repeat(4, 1fr);
                }
            }
            @container productlist (max-width: 1208px) {
                .product-container {
                    grid-template-columns: repeat(3, 1fr);
                }
            }
            @container productlist (max-width: 902px) {
                .product-container {
                    grid-template-columns: repeat(2, 1fr);
                }
            }

            .product-box {
                max-width: 492px;
                //flex: 1 0 328px;
                background-color: #fff;
                box-shadow: 1px 1px 4px -2px rgba(0, 0, 0, 0.25),
                -1px -1px 4px -2px rgba(0, 0, 0, 0.25);
                text-align: center;
                padding: 12px;
                border-radius: 8px;
                text-align: start;

                display: grid;
                align-items: center;
                grid-template-columns: 1fr 1fr;
                grid-template-rows: auto 20px 45px 34px 26px auto 34px auto;
                grid-template-areas:
          "img img"
          "variants variants"
          "name name"
          "description description"
          "rating rating"
          "price price"
          "stockstatus stockstatus"
          "buyButton buyButton";
                gap: 10px;
            }

            :host([layout="list"]) .product-box {
                max-width: 100%;
                flex-basis: auto;
                text-align: left;
                display: grid;
                grid-template-columns: minmax(100px, 278px) minmax(240px, 3fr) 1fr 1fr;
                grid-template-rows: repeat(7, auto);
                grid-template-areas:
          "img variants price price"
          "img name price price"
          "img description price price"
          "img rating price price"
          "img rating buyButton ."
          "img stockstatus buyButton compare"
          "img productnumbers . .";
        gap: 10px;
      }

      .productnumbers {
          display: none;
      }
        
      :host([layout="list"]) .productnumbers {
          display: block;
      }

    @container product-box-container (max-width: 768px) {
        :host([layout="list"]) .productnumbers {
            display: block;
            grid-column: 1 / 2;
        }

        .product-container {
            grid-template-columns: 1fr;
        }

        .product-box,
        :host([layout="list"]) .product-box {
            max-width: 100%;
            flex: 1 0 100%;
            grid-template-columns: 1fr 1fr;
            grid-template-rows: repeat(6, auto);
            grid-template-areas:
      "img name"
      "img description"
      "img price"
      "rating variants"
      "stockstatus stockstatus"
      "buyButton buyButton";
                gap: 7px;
            }

            .product-box .name {
                font-size: 14px;
            }
        }
            
        @media (max-width: 768px) {
        :host([layout="list"]) .productnumbers {
            display: block;
            grid-column: 1 / 2;
        }
          
        .product-container {
          grid-template-columns: 1fr;
        }
        
        .product-box,
        :host([layout="list"]) .product-box {
          max-width: 100%;
          flex: 1 0 100%;
          grid-template-columns: 1fr 1fr;
          grid-template-rows: repeat(6, auto);
          grid-template-areas:
            "img name"
            "img description"
            "img price"
            "rating variants"
            "stockstatus stockstatus"
            "buyButton buyButton";
          gap: 7px;

          .name {
            font-size: 14px;
          }
        }
        
        .productnumbers {
            display: none;
        }
      }

      .product-box .main-link {
        grid-column: 1 / -1;
        grid-row: 1 / -1;
        height: 100%;
        outline-offset: 8px;
      }

      .product-box:has(.main-link:not(:active)) {
        transition: background-color 1000ms;
      }

      .product-box:has(.main-link:active) {
        background: color-mix(in srgb, var(--store-color, #f8b912) 50%, white);
      }
      
      .product-box-active {
        background: color-mix(in srgb, var(--store-color, #f8b912) 50%, white);
      }

      .product-box * {
        margin: 0;
        cursor: pointer;
        pointer-events: none;
      }

      .product-box a,
      .product-box button {
        pointer-events: initial;
        cursor: pointer;
      }

      .name {
        grid-area: name;
        align-self: end;
        font-size: 18px;
        font-weight: 500;
        color: #222;
      }

      .product-box:hover .name {
        text-decoration: underline;
        text-underline-offset: 4px;

      }

      .description {
        grid-area: description;
        align-self: start;
        font-size: 14px;
      }

      .clamp-lines {
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        display: -webkit-box;
        text-wrap: balance;
      }

      .product-image {
        grid-area: img;
        width: 100%;
        height: auto;
        aspect-ratio: 200 / 150;
        background-size: contain;
        pointer-events: auto;
      }
      
      .product-image img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
      
      .product-box .product-image-second {
        display: none;
      }

      .product-image-first {
        cursor: pointer;
      }

      .product-image-first:hover {
        opacity: 0;
      }

      .product-image-first:hover + .product-image-second {
        display: block;
      }

      .comparison {
        grid-area: compare;
        display: flex;
        gap: 8px;
        justify-content: center;
        align-items: center;
        color: #565656;
        font-size: 14px;
        border: none;
        border-radius: 4px;
        background-color: #fff;
        padding: 8px 16px;
      }

      .comparison:hover {
        background-color: #f5f5f5;
        transform: translate(-1px, 0);
      }

      .cta-container {
        grid-area: buyButton;
        .buy-button {
          border: none;
          border-radius: 5px;
          padding: 8px 24px;
          color: #222;
          background-color: var(--store-color, #f8b912);
          text-align: center;
          width: 100%;
          font-size: 16px;
        }

        .buy-button:hover {
          opacity: 0.7;
        }
      }
    `,
    ];
}
customElements.define("product-box", ProductBox);